import { useNavigate } from 'react-router-dom';

import { Box, Divider, useTheme } from '@mui/material';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';

import { orderFulfillmentStatusOptions, orderPaymentStatusOptions } from 'components/_common/constants/StatusOptions';
import { StatusChip } from 'components/_common/StatusChip';
import { useFormat } from 'components/hooks/useFormat';
import { Typography } from 'styled';

interface SecondaryOrderDetailsProps {
  order?: Order;
}

export const SecondaryOrderDetails = ({ order }: SecondaryOrderDetailsProps) => {
  const theme = useTheme();
  const { formatDate } = useFormat();
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={{ xs: 1, lg: 4 }}>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
          Date
        </Typography>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.primary }}>
          {formatDate(order?.createdDate, false, 'MMMM dd, yyyy')}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
          Customer
        </Typography>
        <Typography
          sx={{ color: '#0071E3', cursor: 'pointer' }}
          onClick={() => navigate(`/customers/${order?.customer?.memberKey}`, { state: { refetch: true } })}
        >
          {`${order?.customer?.firstName} ${order?.customer?.lastName}`}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
          Risk evaluation
        </Typography>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.primary }}>
          n/a
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem />
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
          Payment
        </Typography>
        {order?.orderPaymentStatus && (
          <StatusChip
            status={orderPaymentStatusOptions[order?.orderPaymentStatus] || order?.orderPaymentStatus}
            width="auto"
          />
        )}
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
          Fulfillment
        </Typography>
        {order?.orderFulfillmentStatus && (
          <StatusChip
            status={orderFulfillmentStatusOptions[order?.orderFulfillmentStatus] || order?.orderFulfillmentStatus}
            width="auto"
          />
        )}
      </Box>
    </Box>
  );
};
