import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import { OrderItemSummaryBase } from '@one/api-models/lib/Admin/Sales/OrderItemSummaryBase';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';

import { Dialog, DialogContent, DialogTitle, IconButton } from 'styled';

import { OrderItemDetailsSection } from './OrderItemDetailsSection';

interface OrderItemDetailsDialogProps {
  open: boolean;
  testIdPrefix: string;
  orderItem: OrderItemSummaryBase;
  purchaseSummary: PurchaseSummary;
  handleClose: () => void;
}

export const OrderItemDetailsDialog = ({
  open,
  testIdPrefix,
  handleClose,
  orderItem,
  purchaseSummary,
}: OrderItemDetailsDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth: '1200px',
        },
      }}
    >
      <DialogTitle>
        Order Item
        <IconButton onClick={handleClose} size="small" disableRipple data-testid={`${testIdPrefix}CloseButton`}>
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <OrderItemDetailsSection purchaseSummary={purchaseSummary} orderItem={orderItem} />
      </DialogContent>
    </Dialog>
  );
};
