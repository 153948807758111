import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';

import { selectIsLoadingCreatePaymentMethod, setCreatePaymentMethodError } from 'slices/paymentDataSlice';

import { PaymentProvider } from '../PaymentProvider';

interface AddPaymentMethodDialogProps {
  open: boolean;
  testId: string;
  onClose: () => void;
  refetchPaymentMethodList: () => void;
  setAddPaymentModalOpen: (value: boolean) => void;
}

export const AddPaymentMethodDialog = ({
  open,
  testId,
  onClose,
  refetchPaymentMethodList,
  setAddPaymentModalOpen,
}: AddPaymentMethodDialogProps) => {
  const dispatch = useDispatch();
  const isLoadingCreatePaymentMethod = useSelector(selectIsLoadingCreatePaymentMethod);

  const resetCreatePaymentMethodError = () => {
    dispatch(setCreatePaymentMethodError(undefined));
  };

  const closeModal = () => {
    resetCreatePaymentMethodError();
    onClose();
  };

  return (
    <Dialog open={open} onClose={closeModal} maxWidth="xs" fullWidth>
      <DialogTitle
        variant="h6"
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', boxShadow: 'none' }}
      >
        <Box>Add payment method</Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
          disableRipple
          disabled={isLoadingCreatePaymentMethod}
          data-testId={`${testId}CloseButton`}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box sx={{ position: 'relative' }}>
        <PaymentProvider
          type="paymentPlans"
          testId={testId}
          onClose={onClose}
          resetCreatePaymentMethodError={resetCreatePaymentMethodError}
          refetchPaymentMethodList={refetchPaymentMethodList}
          setAddPaymentModalOpen={setAddPaymentModalOpen}
        />
      </Box>
    </Dialog>
  );
};
