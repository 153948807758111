import { Box, Typography } from '@mui/material';
import { CalculateCancellationOutcomeResponse } from '@one/api-models/lib/Admin/Orders/Common/CalculateCancellationOutcomeResponse';

import { AlternativeCurrency } from 'components/_common/alternativeCurrency/AlternativeCurrency';
import { useFormat } from 'components/hooks/useFormat';
import OrderCancellationForm from 'models/customers/profile/OrderCancellationForm';

interface Props {
  data: OrderCancellationForm;
  outcome: CalculateCancellationOutcomeResponse;
}

export const CancellationSummary = ({ data, outcome }: Props) => {
  const { formatCurrency: formatCurrencyFunc } = useFormat();
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };
  return (
    <Box>
      <Typography variant="h6">
        You are going to cancel order <strong>{data.orderNumber}</strong> with the following outcome:
      </Typography>

      <ul>
        {data.amountToBeRefunded != null && outcome.amountPaid && (
          <li>
            <Typography variant="subtitle1">Amount to be refunded:</Typography>
            <Typography variant="subtitle2">
              {formatCurrency(data.amountToBeRefunded, outcome.amountPaid?.currency)}
            </Typography>
          </li>
        )}
        {data.items.map((item) => (
          <>
            <li key={item.orderItemId}>
              <Typography variant="subtitle1">
                {item.isCancellableAtSupplier ? (
                  <>
                    The order item <strong>will {item.cancelAtSupplier ? '' : 'not'} be cancelled</strong> at the
                    supplier.
                  </>
                ) : (
                  'This order has to be manually canceled at supplier.'
                )}
              </Typography>
            </li>
            {item.burn.map((burn) => (
              <li key={`${item.orderItemId}_${burn.code}`}>
                <Typography variant="subtitle1">
                  {burn.name} to be reissued:
                  <AlternativeCurrency data={{ alternativeCurrencyDefinition: { ...burn }, total: burn.value }} />
                </Typography>
              </li>
            ))}

            {item.earn.map((earn) => (
              <li key={`${item.orderItemId}_${earn.code}`}>
                <Typography variant="subtitle1">
                  {earn.name} to be revoked:
                  <AlternativeCurrency data={{ alternativeCurrencyDefinition: { ...earn }, total: earn.value }} />
                </Typography>
              </li>
            ))}

            {item.certificates.map((certificate) => (
              <li key={certificate.serialNumber}>
                <Typography variant="subtitle1">
                  New certificate <strong>will {certificate.shouldReissue ? '' : 'not'} be issued</strong>
                  {` with name ${certificate.name}.`}
                </Typography>
              </li>
            ))}
          </>
        ))}
      </ul>
    </Box>
  );
};
