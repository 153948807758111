export enum Partner {
  Princess = 'PRI',
  GuestBookings = 'GBK',
  GuestBookingsUK = 'GBU',
  CapitalVacations = 'CAV',
  PrincessPromotions = 'PPR',
  RedeemYourTravel = 'RYT',
  Prestigate = 'PRG',
  MagicBreak = 'MBR',
  HolidayInnClub = 'HIC',
  GrandPacificResorts = 'GPR',
  RSI = 'RSI',
  CCM = 'CCM',
  ElCid = 'ECD',
  UVC = 'UVC',
  LeisureRewards = 'LRS',
  Vidanta = 'VID',
}
