import { Box, Grid } from '@mui/material';
import { OrderItemSummaryBase } from '@one/api-models/lib/Admin/Sales/OrderItemSummaryBase';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';
import { ReservationItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Accommodation/ReservationItemSummary';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/CruiseOrderItemSummary';

import { SectionTitle } from 'components/_common/SectionTitle';
import { CruiseOrderItemDetails } from 'components/views/customers/components/profile/Assets/OrderDetails/CruiseOrderItemDetails';
import { CruisePricing } from 'components/views/customers/components/profile/Assets/OrderDetails/CruisePricing';
import { Info } from 'components/views/customers/components/profile/Assets/OrderDetails/Info';
import { ReservationOrderItemDetails } from 'components/views/customers/components/profile/Assets/OrderDetails/ReservetionOrderItemDetails';

interface Props {
  orderItem: OrderItemSummaryBase;
  purchaseSummary: PurchaseSummary;
}
//TODO rewrite entire component
export const OrderItemDetailsSection = ({ orderItem, purchaseSummary }: Props) => {
  let detailsComponent = null;
  let pricingComponent = null;

  switch (orderItem.$type) {
    case CruiseOrderItemSummary.$type: {
      detailsComponent = <CruiseOrderItemDetails orderItem={orderItem as CruiseOrderItemSummary} />;
      pricingComponent = <CruisePricing purchaseSummary={purchaseSummary} />;
      break;
    }
    case ReservationItemSummary.$type: {
      const reservationItem = orderItem as ReservationItemSummary;
      detailsComponent = <ReservationOrderItemDetails orderItem={reservationItem} />;
      break;
    }
  }
  return (
    <Box>
      {detailsComponent}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <SectionTitle title="Supplier details" sx={{ mb: 0 }} variant="h6" />
            </Grid>
            <Grid item xs={6}>
              <Info label="Supplier / Agency" value={orderItem.supplierIdentifier} />
            </Grid>
            <Grid item xs={6}>
              <Info label="Booking reference" value={orderItem.supplierOrderReference} />
            </Grid>
          </Grid>
        </Grid>
        {pricingComponent && (
          <Grid item xs={12} md={6}>
            {pricingComponent}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
